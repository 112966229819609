import { Button } from "../components/Common/Button";
import { Logo } from "../components/Common/Logo";
import { Contact } from "../components/Contact";
import warehouseImage from "../assets/warehouse.jpg";
import teamImage from "../assets/team.jpg";
import { useNavigate } from "react-router-dom";
import starLogo from "../assets/star_logo.png";
import starFitting from "../assets/star-fitting.png";
import S2000 from "../assets/products/straight-pipes/2000.png";
import C1600 from "../assets/products/pipe-cutting/1600.png";
import CTY2000 from "../assets/products/fitting-fabrication/cty/2000.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import queroLogo from "../assets/logos/quero.png";
import superLogo from "../assets/logos/super.png";
import weldpolyLogo from "../assets/logos/weldpoly.png";
import desPlastLogo from "../assets/logos/desplast.png";
import pntLogo from "../assets/logos/pnt.png";
import kapLogo from "../assets/logos/kap.png";
import polyWeldLogo from "../assets/logos/poly-weld.jpg";

export const Home = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="h-[calc(100vh-5rem)] w-full bg-background hero-image border-b-8 border-accent">
        <div className="bg-background/80 h-full w-full relative">
          <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full px-5 md:px-0">
            <div className="grid md:grid-cols-12 items-center justify-between text-center md:text-left h-full w-full">
              <div className="flex flex-col justify-center gap-5 col-span-9">
                <div className="font-bold tracking-widest text-lg uppercase text-primary">
                  <Logo className="h-24" />
                </div>
                <div className="font-bold text-6xl md:text-7xl text-white mb-2">
                  Australian Managed with Real-time Global Warranty Coverage
                </div>
                <div className="font-medium text-white mb-5 text-2xl">
                  Supplying HDPE welding equipment and sharing 32+ years of
                  technology and experience.
                </div>
              </div>
              <div className="col-span-5"></div>
            </div>

            {true && (
              <div className="h-24 -mt-12 w-full rounded-lg bg-white border-8 border-accent">
                {/* <div className="grid grid-cols-3 items-center justify-center gap-x-7 md:gap-14 w-full h-full"> */}
                <Carousel
                  className="h-full"
                  autoPlaySpeed={1900}
                  autoPlay
                  showDots={false}
                  arrows={false}
                  responsive={{
                    superLargeDesktop: {
                      // the naming can be any, depends on you.
                      breakpoint: { max: 4000, min: 3000 },
                      items: 5,
                    },
                    desktop: {
                      breakpoint: { max: 3000, min: 1024 },
                      items: 5,
                    },
                    tablet: {
                      breakpoint: { max: 1024, min: 464 },
                      items: 3,
                    },
                    mobile: {
                      breakpoint: { max: 464, min: 0 },
                      items: 1,
                    },
                  }}
                  infinite
                  shouldResetAutoplay
                >
                  {[
                    queroLogo,
                    weldpolyLogo,
                    superLogo,
                    desPlastLogo,
                    pntLogo,
                    kapLogo,
                    polyWeldLogo,
                  ].map((image, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-center px-6"
                    >
                      <img src={image} className="max-h-14 w-auto" />
                    </div>
                  ))}
                </Carousel>
              </div>
            )}

            <div className="absolute bottom-14 left-0 right-0">
              <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full px-5 md:px-0">
                <div className="flex items-center justify-end">
                  <div className="flex flex-col gap-4">
                    <div className="text-xs uppercase text-[#0089c1] font-bold">
                      In collaboration with
                    </div>

                    <div className="flex">
                      <a
                        href="https://starpipingsystems.de/en/"
                        target="_blank"
                        className="group cursor-pointer"
                      >
                        <img
                          src={starLogo}
                          className="w-auto h-16 group-hover:scale-105 transition ease-in-out"
                        />
                      </a>

                      <div className="-ml-5 -mt-8">
                        <img src={starFitting} className="w-auto h-44" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full pt-10 md:pt-20 px-5 md:px-0">
        <div className="mx-auto lg:container">
          <div className="grid grid-cols-3 items-center justify-center gap-9">
            <div
              className="text-lg py-3 flex flex-col items-center justify-center group rounded-lg border-8 border-primary hover:bg-primary/10 cursor-pointer transition ease-in-out"
              onClick={() => navigate("/products")}
            >
              <img
                src={S2000}
                className="w-auto h-32 scale-90 group-hover:scale-95 transition ease-in-out"
              />
              <div className="font-bold">WPF2000</div>
              <div className="text-xs opacity-50">Straight Pipe Machine</div>
            </div>
            <div
              className="text-lg py-3 flex flex-col items-center justify-center group rounded-lg border-8 border-primary hover:bg-primary/10 cursor-pointer transition ease-in-out"
              onClick={() => navigate("/products")}
            >
              <img
                src={C1600}
                className="w-auto h-32 scale-90 group-hover:scale-95 transition ease-in-out"
              />
              <div className="font-bold">WPFS1600</div>
              <div className="text-xs opacity-50">Pipe Saw Machine</div>
            </div>
            <div
              className="text-lg py-3 flex flex-col items-center justify-center group rounded-lg border-8 border-primary hover:bg-primary/10 cursor-pointer transition ease-in-out"
              onClick={() => navigate("/products")}
            >
              <img
                src={CTY2000}
                className="w-auto h-32 scale-90 group-hover:scale-95 transition ease-in-out"
              />
              <div className="font-bold">WPFW2000</div>
              <div className="text-xs opacity-50">
                Crosses, Tees & Ys Machine
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full pt-10 md:py-10 px-5 md:px-0">
        <div className="grid md:grid-cols-2 items-center">
          <div className="relative hidden md:block">
            <img src={teamImage} className="home-image rounded-lg" />
            <div className="absolute left-0 bottom-0 p-5 bg-primary rounded-tr-lg flex flex-col items-center justify-center ml-3 text-background">
              <div className="text-4xl font-bold">32+</div>
              <div className="uppercase font-bold text-sm">
                years of experience
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="font-bold tracking-widest text-lg uppercase text-accent text-center md:text-left">
              WHY CHOOSE US
            </div>
            <div className="font-bold text-6xl text-background text-center md:text-left">
              What Makes Weco Polymer Fusion Your Ideal Partner
            </div>
            <div className="text-gray-600 text-center md:text-left">
              With over 32 years of industrial experience and a legacy of
              innovation, WP Fusion is your trusted partner for cutting-edge
              polymer welding solutions.
            </div>

            <div className="rounded-lg bg-primary flex p-5 text-background gap-3 mt-3">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-9 h-9"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>

              <div className="flex flex-col gap-1">
                <div className="font-bold text-2xl">
                  Product Development and Innovation
                </div>
                <div>
                  Utilize insights from market research to develop innovative
                  polymer welding equipment solutions that address customer
                  needs effectively.
                </div>
              </div>
            </div>

            <div className="rounded-lg bg-background flex p-5 text-primary gap-3">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-9 h-9"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>

              <div className="flex flex-col gap-1">
                <div className="font-bold text-2xl">
                  Timely Delivery and Support Services
                </div>
                <div>
                  Commit to delivering products on time and prodivde efficient
                  after-sales support services. Offer technical assistance,
                  maintenance and troubleshooting.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full pb-28 mt-16 md:pb-40 px-5 md:px-0">
        <div className="grid md:grid-cols-2 items-center">
          <div className="flex flex-col gap-4">
            <div className="font-bold tracking-widest text-lg uppercase text-accent text-center md:text-left">
              What We Offer
            </div>
            <div className="font-bold text-6xl text-background text-center md:text-left">
              Experience Efficiency & Reliability with Every Purchase
            </div>
            <div className="text-gray-600 text-center md:text-left">
              Our commitment to innovation ensures that each product is designed
              to optimize your workflow, while our unwavering dedication to
              quality guarantees consistent, dependable performance.
            </div>

            <div className="rounded-lg bg-primary flex p-5 text-background gap-3 mt-3">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-9 h-9"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>

              <div className="flex flex-col gap-1">
                <div className="font-bold text-2xl">
                  Commitment to Excellence
                </div>
                <div>
                  Our commitment to excellence ensures that every product you
                  choose is crafted with precision, innovation, and quality,
                  empowering you to tackle your polymer welding projects with
                  confidence.
                </div>
              </div>
            </div>

            <div className="rounded-lg bg-background flex p-5 text-primary gap-3">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-9 h-9"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>

              <div className="flex flex-col gap-1">
                <div className="font-bold text-2xl">
                  Unmatched Performance and Reliability
                </div>
                <div>
                  We believe in delivering more than just products – we provide
                  solutions that redefine efficiency and reliability. With every
                  purchase, you're not just investing in equipment; you're
                  investing in peace of mind, knowing that you have the tools to
                  succeed.
                </div>
              </div>
            </div>
          </div>

          <div className="relative hidden md:block">
            <img
              src={warehouseImage}
              className="home-image rounded-lg float-right"
            />
            <div className="absolute left-28 bottom-0 p-5 bg-primary rounded-tr-lg flex flex-col items-center justify-center ml-3 text-background">
              <div className="text-4xl font-bold">25+</div>
              <div className="uppercase font-bold text-sm">
                diverse products
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-quote-section">
        <div className="bg-background/90 h-full w-full">
          <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full py-40 text-center capitalize">
            <div className="text-accent font-bold tracking-widest uppercase mb-5">
              Get a Quote
            </div>

            <div className="text-white font-bold text-5xl">
              Are you prepared to{" "}
              <span className="text-primary">get your project started</span>?
            </div>
            <div className="text-white font-bold text-5xl mb-10">
              Get a quote right now!
            </div>

            <Button
              className="!font-semibold"
              buttonStyle="primary"
              label="Get Started"
              size="large"
              rightIcon
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                    clipRule="evenodd"
                  />
                </svg>
              }
              onClick={() => navigate("/contact")}
            />
          </div>
        </div>
      </div>

      <Contact />
    </div>
  );
};
